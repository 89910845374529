import React, {Component} from 'react';
import {connect} from "react-redux";
import {ASSETS} from "../../constants/paths";
import LazyLoad from "react-lazyload";
import {getElementWidth} from "../../helper";

class SmallSliderComponent extends Component {
    imgItem = React.createRef();
    translateValue;
    state = {
        itemWidth: 0
    }
    calculateItemWidth = () => {
        this.setState({
            itemWidth: getElementWidth(this.imgItem.current)
        })

    }
    setTranslateValue = () => {
        this.calculateItemWidth()
        let {activeIndex, children} = this.props;
        if (children.length - 1 > activeIndex) {
            this.translateValue = 0
        }
        if (activeIndex > 2) {
            this.translateValue = this.state.itemWidth * (activeIndex - 2)
        }
        this.forceUpdate()
    }

    componentDidMount() {
        /*todo: başka yol olabilir timeout haric !! {emre}*/
        setTimeout(() => {
            this.calculateItemWidth()
        }, 300)
        if (typeof window !== "undefined") {
            window.addEventListener("resize", this.setTranslateValue)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeIndex !== this.props.activeIndex) {
            this.setTranslateValue()
        }
    }

    componentWillUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.setTranslateValue)
        }
    }

    render() {
        let {selectedColor, activeIndex} = this.props
        return (
            <div className="mini-img-block">
                <div className="mini-slider-block" style={{transform: `translate3d(-${this.translateValue}px,0,0)`}}>
                    {
                        (selectedColor&&selectedColor.detail&&selectedColor.detail.gallery_images) && JSON.parse(selectedColor.detail.gallery_images).map((img, index) =>
                            <div ref={this.imgItem} id="imgId"
                                 className={`img-item ${activeIndex === index ? "active" : ""}`} key={index}
                                 onClick={() => this.props.changeIndex(index)}>
                                <LazyLoad>
                                    <picture>
                                        <source
                                            srcSet={`${ASSETS}${img.substring(0, img.lastIndexOf("."))}.webp 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x.webp 2x`}/>
                                        <source
                                            srcSet={`${ASSETS}${img} 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x${img.substring(img.lastIndexOf("."))} 2x`}/>
                                        <img src={`${ASSETS}${img}`}  alt={selectedColor.name}/>
                                    </picture>
                                </LazyLoad>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedColor: state.userSelectionReducer.selectedColor
})
export default connect(mapStateToProps)(SmallSliderComponent);
