import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import {
    CONTRACT,
    HOME_PAGE,
    KVKK_PAGE,
    LEGAL_WARNING,
    PAYMENT_RESULT,
    PAYMENT_RESULT_WITHOUT_PARAM,
    PAYMENT_STAGE,
    PAYMENT_STAGE_WITHOUT_PARAM,
    PERSONAL_INFO,
    SELECT_CAR_PAGE,
    SELECT_DESIGN,
    SELECT_VERSION,
    SUMMARY_INFO,
    WHY_ONLINE_PAGE
} from "../constants/routePaths";
import HomePage from "../pages/homePage";
import HeaderComponent from "./layout/headerComponent";
import BreadCrumpComponent from "./breadCrumpComponent";
import SelectModelPage from "../pages/selectModelPage";
import SelectVersionPage from "../pages/selectVersionPage";
import SelectDesignPage from "../pages/selectDesignPage";
import PersonalInfoComponent from "../pages/personalInfoPage";
import FooterComponent from "./layout/footerComponent";
import OrderSummeryPage from "../pages/orderSummeryPage";
import PaymentPage from "../pages/paymentPage";
import PaymentResultPage from "../pages/paymentResultPage";
import WhyOnlinePage from "../pages/whyOnlinePage";
import LegalWarning from "../pages/legalWarning"
import {connect} from "react-redux";
import KvkkComponent from "../pages/kvkkPage";
import ContractPage from '../pages/contractPage';
import NotFoundPage from "../pages/notFoundPage";



class RoutesComponent extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof window !== "undefined" && prevProps.location.pathname !== this.props.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        let {breadCrumbLinks} = this.props;
        return (
            <div>
                <Switch>
                    <Route exact path={CONTRACT} component={null}/>
                    <Route component={HeaderComponent}/>
                </Switch>
                {
                    this.props.location.pathname !== HOME_PAGE && this.props.location.pathname !== WHY_ONLINE_PAGE && this.props.location.pathname !== LEGAL_WARNING && this.props.location.pathname !== KVKK_PAGE && `/${this.props.location.pathname.split("/")[1]}` !== PAYMENT_RESULT_WITHOUT_PARAM && breadCrumbLinks&&

                    <Switch>
                        <Route exact path={CONTRACT} component={null}/>
                        <Route  component={BreadCrumpComponent}/>
                    </Switch>
                }
                <Switch>
                    <Route exact path={HOME_PAGE} component={HomePage}/>
                    <Route exact path={LEGAL_WARNING} component={LegalWarning}/>
                    <Route exact path={SELECT_CAR_PAGE} component={SelectModelPage}/>
                    <Route exact path={SELECT_VERSION} component={SelectVersionPage}/>
                    <Route exact path={SELECT_DESIGN} component={SelectDesignPage}/>
                    <Route exact path={PERSONAL_INFO} component={PersonalInfoComponent}/>
                    <Route exact path={SUMMARY_INFO} component={OrderSummeryPage}/>
                    <Route exact path={PAYMENT_STAGE} component={PaymentPage}/>
                    <Route exact path={PAYMENT_RESULT} component={PaymentResultPage}/>
                    <Route exact path={WHY_ONLINE_PAGE} component={WhyOnlinePage}/>
                    <Route exact path={LEGAL_WARNING} component={LegalWarning}/>
                    <Route exact path={KVKK_PAGE} component={KvkkComponent}/>
                    <Route exact path={CONTRACT} component={ContractPage}/>
                    <Route component={NotFoundPage}/>
                </Switch>
                <Switch>
                    <Route exact path={CONTRACT} component={null}/>
                    <Route component={FooterComponent}/>
                </Switch>
                {
                    ((this.props.location.pathname === SUMMARY_INFO || this.props.location.pathname === PAYMENT_STAGE_WITHOUT_PARAM || this.props.location.pathname === PAYMENT_RESULT_WITHOUT_PARAM ) &&  !breadCrumbLinks) &&
                    <Redirect to={SELECT_CAR_PAGE}/>
                }
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    models: state.modelsReducer.models,
    breadCrumbLinks: state.commonReducer.breadCrumbLinks
})
export default withRouter(connect(mapStateToProps)(RoutesComponent));
