export const dataStandardized = (unStandardData, firstParam, secParam, isUpper = true) => {
    return [...unStandardData.map((x) => {
        return { value: x[firstParam], label: isUpper ? x[secParam].toString().toLocaleUpperCase("tr").trim(): x[secParam].toString().trim() }
    })];
};
export const checkMonth = (value,name)=>{
    if(!value){
        return value
    }
    const nums = value.replace(/[^\d]/g, '')
    if(nums.length === 1 ){
        return 0+nums
    }
    if(nums>12){
        return 12
    }
    else{
        return nums
    }
}
export const normalizePhone = value => {
    if (!value) {
        return value
    }

    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return `${onlyNums.length>0 ? "(":""}${onlyNums}${onlyNums.length>3?")":""}`
    }
    if (onlyNums.length <= 6) {
        return `(${onlyNums.slice(0, 3)})${onlyNums.length>3?" ":""}${onlyNums.slice(3)}`
    }
    return `(${onlyNums.slice(0, 3)})${onlyNums.length>3?" ":""}${onlyNums.slice(3, 6)}${onlyNums.length>6?" ":""}${onlyNums.slice(6,8)}${onlyNums.length>8?" ":""}${onlyNums.slice(8,10)}`
}

export const maxLen100 = (value) => {
    return value ? value.slice(0, 100) : "";
}
export const checkTcNo = (value) => {
    if (!value) {
        return value
    }
    let term = value;
    if (value.length > 11) {
        term = value.substring(0, 11)
    }
    const onlyNums = term ? term.replace(/[^\d]/g, '') : null

    return `${onlyNums}`;
};
export const getElementWidth = (element) => {
    if (element) {
        let style = element.currentStyle || (typeof window !== "undefined"&& window.getComputedStyle(element)),
            width = element.offsetWidth,
            margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
        return width + margin;
    }
}
export const checkNumber=value=>{
    if (value){
        const values = value.replace(/[^\d]/g, '')
        return values
    }
}
export const checkLetter=value=>{
    if (value){
        const values = value.replace(/[`!@#$%^&*()_+-={};':"|,.<>?~]/,'')
        return values
    }
}



export const normalizeCard = value => {

    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 4) {
        return `${onlyNums.length>4?"-":""}${onlyNums}`
    }
    if (onlyNums.length <= 8) {
        return `${onlyNums.slice(0, 4)}${onlyNums.length>4?"-":""}${onlyNums.slice(4)}`
    }
    if (onlyNums.length <= 12) {
        return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 8)}${onlyNums.length>8?"-":""}${onlyNums.slice(8)}`
    }
    if (onlyNums.length <= 16) {
        return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 8)}-${onlyNums.slice(8, 12)}${onlyNums.length>12?"-":""}${onlyNums.slice(12)}`
    }
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 8)}-${onlyNums.slice(8,12)}-${onlyNums.slice(12,16)}`
}
export const getElementPosition = (el) => {
    var xPos = 0;
    var yPos = 0;
    while (el) {
        if (el.tagName === "BODY") {
            xPos += (el.offsetLeft + el.clientLeft);
            yPos += (el.offsetTop + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
    }
    return {
        x: xPos,
        y: yPos
    };
};

export function scrollToFirstError(errors) {
    if (errors) {
        const firstError = Object.keys(errors)[0];
        let offset;
        Object.keys(errors).map((errorItem) => {
            console.log("errorItem",errorItem)
            const el =  document.querySelector(`[data-name="${errorItem}"]`);
            if (getElementPosition(el).y !== 0 && (getElementPosition(el).y < offset || !offset)) {
                offset = getElementPosition(el).y;
            }
        })
        window.scrollTo({ top: offset - 300, behavior: 'smooth' });
    }
}

export const changeDocumentMetas = (titleValue,titleDesc) => {
    if (typeof window !== "undefined") {
        if (titleValue !== null) {
            document.title = titleValue;
        }

        const metaDesc = document.querySelector('meta[name="description"]');
        if (metaDesc !== null) {
            metaDesc.content = titleDesc;
        }
    }
};
