import React, {Component} from 'react';
import LazyLoad from "react-lazyload";
import {ASSETS} from "../constants/paths";
import {changeDocumentMetas} from "../helper";

class WhyOnlinePage extends Component {
    titleValue="Neden Online Rezervasyon? | Honda Online";
    titleDesc="Online rezervasyonun sizin için sağlayacağı kolaylıkları ve neden Honda Online’ı tercih etmeniz gerektiğini bu sayfadan öğrenebilirsiniz.";
    constructor() {
        super();
        changeDocumentMetas(this.titleValue,this.titleDesc)
    }
    render() {
        return (
            <div className="why-honda-root home-root">
                   <div className="title-container">
                       <h1>HONDA Online rezervasyonu neden tercih etmeliyim?</h1>
                       <p>Vivamus suscipit, orci ut imperdiet commodo, lorem leo sollicitudin diam</p>
                   </div>
                   <div className="double-content-container">
                       <div className="content-block">
                           <div className="text-block">
                               <div className="explain-block">
                                   <h2>Hızlı ve Konforlu</h2>
                                   <p>5 adımda rezervasyonunuzu kolay ve hızlı bir
                                       şekilde tamamlayabilirsiniz. </p>
                                   <p>Honda otomobiliniz ile ilgili detayları ve tüm
                                       özellikleri hızlıca inceleyebilirsiniz. </p>
                                   <p>Aracınızı bayiden teslim alabilir veya adresinize gelmesini sağlayabilirsiniz. </p>
                                   <p>Rezervasyonunuzu oluşturduktan sonra Honda
                                       bayi yetkilisi tarafından telefon ile aranırsınız. </p>
                                   <p>Rezervasyonunuz ile ilgili her türlü gelişmeden anında haberdar olursunuz. </p>

                               </div>
                           </div>
                           <div className="image-block">
                               <LazyLoad>
                                   <picture>
                                       <source
                                           srcSet={`${ASSETS + "/img/why-honda-image-1.webp"} 1x, ${ASSETS + "/img/why-honda-image-1_2x.webp"} 2x `}
                                           type="image/webp"/>
                                       <source
                                           srcSet={`${ASSETS + "/img/why-honda-image-1.png"} 1x, ${ASSETS + "/img/why-honda-image-1_2x.png"} 2x `}
                                           type="image/jpeg"/>
                                       <img  src={ASSETS + "/img/why-honda-image-1.png"} alt="Hızlı ve Konforlu"/>
                                   </picture>
                               </LazyLoad>
                           </div>
                       </div>
                       <div className="content-block">
                           <div className="image-block">
                               <LazyLoad>
                                   <picture>
                                       <source
                                           srcSet={`${ASSETS + "/img/why-honda-image-2.webp"} 1x, ${ASSETS + "/img/why-honda-image-2_2x.webp"} 2x `}
                                           type="image/webp"/>
                                       <source
                                           srcSet={`${ASSETS + "/img/why-honda-image-2.png"} 1x, ${ASSETS + "/img/why-honda-image-2_2x.png"} 2x `}
                                           type="image/jpeg"/>
                                       <img  src={ASSETS + "/img/why-honda-image-2.png"} alt="Güvenli"/>
                                   </picture>
                               </LazyLoad>
                           </div>
                           <div className="text-block">
                               <div className="explain-block">
                                   <h2>Güvenli</h2>
                                   <p>Sağlıksal açıdan herkes için riski azaltarak, dilediğiniz yerden hayalinizdeki Honda otomobil için rezervasyon yaptırabilirsiniz. </p>
                                   <p>Kişisel bilgileriniz ve kredi kartı ile yapacağınız ödeme güvenle saklanır. </p>
                               </div>
                           </div>

                       </div>
                   </div>
               </div>
        );
    }
}

export default WhyOnlinePage;