import React, {Component} from 'react';
import LazyLoad from 'react-lazyload';
import {withRouter} from 'react-router-dom';
import {ASSETS} from "../constants/paths";
import {SELECT_DESIGN_WITHOUT_PARAM} from "../constants/routePaths";
import {getDetailData, getSelectedColor} from "../actions";
import {connect} from "react-redux";

class CarVersionComponent extends Component {
   constructor(props) {
      super(props);
      this.state = {
         color: props.colors[0]
      }
   }


   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
         this.setState({
            color: this.props.colors[0]
         })
      }
   }

   setColor = (color) => {
      this.setState({
         color
      })
   }
   handleClick = () => {
      this.props.history.push(`${SELECT_DESIGN_WITHOUT_PARAM}${this.state.color.link}`)
      this.props.dispatch(getDetailData(this.state.color.link))

   }


   render() {
      let {price, model, properties, colors, model_year, year, engine, gearbox_type, version, selectedModel,
         vehicle_id,stock,default_list_img,default_properties} = this.props;

      return (
          <div className={`car-select-block  ${stock==0 ? "no-stock-version": ""}`}>
              <div className="car-select-item">
                  <div className="img-item">
                      {
                          this.state.color && this.state.color.detail ?
                          <LazyLoad>
                              <picture>
                                  <source
                                      srcSet={`${ASSETS}${this.state.color.detail.list_img.substring(0, this.state.color.detail.list_img.lastIndexOf("."))}.webp 1x, ${ASSETS}${this.state.color.detail.list_img.substring(0, this.state.color.detail.list_img.lastIndexOf("."))}_2x.webp 2x`}/>
                                  <source
                                      srcSet={`${ASSETS}${this.state.color.detail.list_img} 1x, ${ASSETS}${this.state.color.detail.list_img.substring(0, this.state.color.detail.list_img.lastIndexOf("."))}_2x${this.state.color.detail.list_img.substring(this.state.color.detail.list_img.lastIndexOf("."))} 2x`}/>
                                  <img src={ASSETS + this.state.color.detail.list_img}
                                       alt={selectedModel.name}/>
                              </picture>
                          </LazyLoad>
                            :
                              (
                                  default_list_img && default_list_img.length>0 ?
                                      <LazyLoad>
                                         <picture>
                                            <source
                                                srcSet={`${ASSETS}${default_list_img.substring(0, default_list_img.lastIndexOf("."))}.webp 1x, ${ASSETS}${default_list_img.substring(0, default_list_img.lastIndexOf("."))}_2x.webp 2x`}/>
                                            <source
                                                srcSet={`${ASSETS}${default_list_img} 1x, ${ASSETS}${default_list_img.substring(0, default_list_img.lastIndexOf("."))}_2x${default_list_img.substring(default_list_img.lastIndexOf("."))} 2x`}/>
                                            <img src={ASSETS + default_list_img}
                                                 alt={model}/>
                                         </picture>
                                      </LazyLoad>
                                      : null
                              )
                      }
                  </div>
                  <div className="name-item">
                      <h2>{model}</h2>
                      <span>{model_year || year}</span>
                  </div>
                  <div className="properties-item">
                      <ul>
                          <li>{engine}</li>
                          <li>{version}</li>
                          <li>{gearbox_type}</li>
                      </ul>
                  </div>

                  {
                      colors ?
                          <div className="text-item">
                              {
                                  properties && properties.map((item, index) =>
                                      <p key={index}>{item}</p>
                                  )
                              }
                          </div>
                          :
                          <div className="text-item">
                              {
                                  default_properties && default_properties.map((items, inx) =>
                                      <p key={inx}>{items}</p>
                                  )
                              }

                          </div>
                  }

                  <span className="price">{price&&price}{" "}<b>TL</b></span>
                  <p className="text">Tavsiye Edilen Fiyat</p>
                  <div className="select-colour-block">
                      {
                          /*todo:id ile activelik kontrolü yapıalcaktır {Emre}*/
                          colors&&colors.map((color, index) =>
                              <div
                                  onClick={() => this.setColor(color)}
                                  className={`select-colour-item ${this.state.color && this.state.color.id === color.id ? "active" : ""} ${colors.length < 2 ? "default" : ""}`}
                                  key={index.toString()}
                                  style={{borderColor: color.detail ? color.detail.color_code : "#FFFF"}}>
                                  <div className="colour"
                                       style={{background: color.detail ? color.detail.color_code : "#FFFF"}}/>
                              </div>
                          )
                      }
                  </div>
                  <button type="button" className="btn-item" onClick={()=>{
                      this.handleClick()

                  }}>SEÇ</button>
              </div>
             {
                stock ==0 &&
                <div className="no-version-text">
                   <p>Şu an için online stoğu bulunmamaktadır.</p>
                   <p>Size en yakın bayimizle <a href="https://www.honda.com.tr/otomobil/sizi-arayalim" target="_blank" rel="noreferrer">iletişime geçebilirsiniz.</a></p>
                </div>
             }
          </div>
      );
   }
}

const mapStateToProps = (state) => ({
   models: state.modelsReducer.models || [],
   model_color: state.userSelectionReducer.model_color || []
})
export default withRouter(connect(mapStateToProps)(CarVersionComponent));
