import React, { Component } from 'react';
import { Field, Form } from "react-final-form";
import TextInputComponent from "../components/formFields/textInputComponent";
import {getBreadCrumbLink} from "../actions";
import { getDealerTransactionDetail, getTransactionDetail, getUserPaymentToken, lockVehicle, Payment3d, saveTransactionInfo } from '../services/webService';
import {breadcrumbArr, PAYZEE_AMOUNT, SUB_MERCHANTID} from '../constants/const';
import {LEGAL_WARNING, SELECT_CAR_PAGE, SUMMARY_INFO} from "../constants/routePaths";
import CheckBoxFieldComponent from "../components/formFields/checkBoxFieldComponent";
import {connect} from "react-redux";
import PaymentErrorLbComponent from "../components/lightBox/paymentErrorLbComponent";
import SummaryVehicleComponent from "../components/summary/summaryVehicleComponent";
import ThreeDSecureLbComponent from "../components/lightBox/3DSecureLbComponent";
import {changeDocumentMetas, checkLetter, checkMonth, checkNumber, normalizeCard} from "../helper";
import LoaderComponent from "../components/common/loaderComponent";
import StockErrorLbComponent from '../components/lightBox/stockErrorLbComponent';
import { PAYZEE_FAIL_URL, PAYZEE_SUCCESS_URL } from '../constants/paths';
import { receiveTransactionInfo } from '../actions/personelActions';
import ReservationLbComponent from "../components/lightBox/reservationLbComponent";


const validate = (values) => {

    const errors = {}
    if (!values.fullName || values.fullName.trim() === "") {
        errors.fullName = "Ad Soyad Giriniz";
    }

    if (!values || !values.card  || values.card.length !== 19 ){
        errors.card ="Kart Numarası Giriniz";
    }

    if (!values || !values.cvv || values.cvv.length !== 3) {
        errors.cvv = "CVV Giriniz";
    }
    if (!values || !values.month || values.month > 12) {
        errors.month = "Ay Bilgisi Giriniz";
    }
    if (!values || !values.year || values.year < 22) {
        errors.year = "Yıl Bilgisi Giriniz";
    }
    if (!values || !values.is_subscribed3 || values.is_subscribed3 === false) {
        errors.is_subscribed3 = true;
    }
    return errors

}

class PaymentPage extends Component {
    titleValue="Ön Ödeme Ekranı | Honda Online";
    titleDesc="Rezervasyonunuzu tamamlamak için güvenli bir şekilde 2.000 TL ödemenizi yapın. Rezervasyonunuzdan kısa bir süre sonra bayimiz sizinle iletişime geçecektir.";
    state = {
        paymentToken: null,
        isLoaderShowing: false,
        error:false,
        treeDSecure:false,
        stockErr:false,
        reservation:false

    }

    constructor(props) {
        super(props);
        getUserPaymentToken().then((res) => {
            if (res.data.success) {
                this.setState({
                    paymentToken: res.data.success
                })
            } else {
                this.setState({
                    paymentToken: false
                })
            }
        }).catch((err)=>{
            console.log("err",err);
        })
        this.setBreadCrumb(props)
        changeDocumentMetas(this.titleValue,this.titleDesc)

    }
    setDataLayer = () => {
        if(!window.dataLayer) window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'eec.checkout_odeme',
            'ecommerce': {
                checkout: {
                    actionField: {
                        step: 6
                    },
                    products: [{
                        'payment': 'Ödeme'
                    }]
                }
            }
        });
    }
    componentDidMount(){
        if(typeof window !== "undefined"){
            this.setDataLayer()
        }
        if(this.props.match.params.transactionId){
            this.getTransaction()
        }
    }
    getTransaction = () => {
        getDealerTransactionDetail(this.props.match.params.transactionId).then((dealerResult)=>{
            if(dealerResult.data.succes){
                getTransactionDetail(this.props.match.params.transactionId).then((result)=>{
                    if(result.data.success){
                        this.props.dispatch(receiveTransactionInfo({personalInfo:dealerResult.data.transactionInfo,vehicleDetail:result.data.success,transactionId:this.props.match.params.transactionId}))
                        this.setState({
                            error:true
                        })
                    }else{
                        this.props.history.replace(SELECT_CAR_PAGE)
                    }
                }).catch((err)=>{
                    this.props.history.replace(SELECT_CAR_PAGE)
                })
            }else{
                this.props.history.replace(SELECT_CAR_PAGE)
            }
        }).catch((err)=>{
            this.props.history.replace(SELECT_CAR_PAGE)
        })

    }
    setBreadCrumb=(props)=>{
        let paymentBreadcrumb = [...breadcrumbArr]
        paymentBreadcrumb.forEach((item)=>{
            // item.link = null;
            item.isActive = false;
        })
        paymentBreadcrumb[5].isActive=true
        paymentBreadcrumb[4].link = SUMMARY_INFO
        this.props.dispatch(getBreadCrumbLink(paymentBreadcrumb))
    }
    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    createRandomString = () => {
        var string = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (var i = 0; i < 10; i++) {
            string += characters.charAt(Math.floor(Math.random() * 10));
        }
        return string;
    }

    createOrderGUID = () => {
        var string = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (var i = 0; i < 10; i++) {
            string += characters.charAt(Math.floor(Math.random() * 10));
        }
        return string+Date.now();
    }


    submit = (values) => {
        this.setState({
            isLoaderShowing: true
        })
        //this.props.dispatch(getPersonalInfoFormData(values))
        this.productId = this.createOrderGUID();

        lockVehicle(this.props.transactionId).then(res=>{
            if(res.data.succes){
                this.payment(values);
            }else{
                this.setState({
                    stockErr:true,
                    isLoaderShowing: false
                })
            }
        }).catch(err=>{
            this.setState({
                stockErr:true,
                isLoaderShowing: false
            })
            console.log("err",err);
        })

    }

    saveTransaction = (params,cb) => {
        saveTransactionInfo({
            token:params.token,
            orderId:params.orderId,
            rnd:params.rnd,
            extraData:params.extraData,
            productId:params.productId,//Product Id
            productName:params.productName,//ProductName
            description:"",//Product Desc
            orderProductList: [
                {
                    merchantId: SUB_MERCHANTID,
                    productId: params.productId,
                    amount: PAYZEE_AMOUNT,
                    productName: params.productName,
                    commissionRate: null,
                    description: params.description
                }
            ]
        },this.props.transactionId).then((res)=>{
            if(res.data.success){
                cb(res.data.success)
            }else{
                cb(false);
            }
        }).catch(()=>{
            cb(false);
        })
    }

    payment = (values) => {
        let OkUrl = PAYZEE_SUCCESS_URL;
        let FailUrl = PAYZEE_FAIL_URL;
        let OrderId = this.props.transactionId;
        let rndm = this.createRandomString()
        let params = {
            token:this.state.paymentToken,
            cardNumber:values.card.split("-").join(""),
            expiryDateMonth:values.month,
            expiryDateYear: values.year,
            cvv: values.cvv,
            okUrl:OkUrl,
            failUrl:FailUrl,
            orderId:OrderId,
            rnd:rndm,
            description:"",//Model bilgisi gonderilecek
            cardHolderName:values.namesurname,
            extraData:JSON.stringify({selectedModel:this.props.summarySelectedModel,selectedColor:this.props.selectedColor,transactionId:this.props.transactionId}),//Model bilgisi gonderilecek
            identityNumber:null,
            fullName:null,
            email:null,
            phone:null,
            address:null,
            city:null,
            town:null,
            productId:this.productId,//Product Id
            productName:`${this.props.selectedModel.brand} ${this.props.selectedModel.model} ${this.props.selectedModel.version} ${this.props.selectedModel.engine} ${this.props.selectedColor.name} ${this.props.selectedModel.model_year}`,//ProductName
            description:""//Product Desc
        }
        this.saveTransaction(params,(hash)=>{
            if(hash){
                params.hash = hash;
                Payment3d(params).then(res => {
                    if(res.status === 200){
                        this.setState({
                            treeDSecure:true,
                            data:res.data,
                            isLoaderShowing:false
                        })
                    }else{
                        this.setState({
                            error:true,
                            isLoaderShowing:false
                        })
                    }
                }).catch((err)=>{
                    this.setState({
                        error:true,
                        isLoaderShowing:false
                    })
                    console.log("err",err);
                })
            }else{
                this.setState({
                    error:true,
                    isLoaderShowing:false
                })
            }

        });

    }

    goBack = () => {
        this.props.history.replace(SUMMARY_INFO)
        //this.props.history.goBack();
    }
    closeLightBox=()=>{
        this.setState({
            error:false,
            stockErr:false,
            reservation:false

        })

    }
    digitController = (value) => {
        if (typeof window !== "undefined"){
            if(value&&value.length===2){
                document.getElementById('year').focus()
            }
        }

    }
    openReservationLb=()=>{
        if (typeof window !== "undefined"){
            document.body.classList.add('no-scroll');
        }

        this.setState({
            reservation:true
        })

    }

    render() {
        let{selectedModel}=this.props
        return (
                <div className="payment-root">
                    {
                        selectedModel ?
                        <div className="payment-wrapper safe-area">
                            <SummaryVehicleComponent/>

                            <div className="payment-container">
                                <div className="title-block"><h1>Ödeme</h1><p>{PAYZEE_AMOUNT.substring(0,1)+"."+PAYZEE_AMOUNT.substring(1,4)} TL tutarındaki ön ödemenizi gerçekleştirmek için kart bilgilerinizi girin.
                                    Satışın olmaması durumunda paranız koşulsuz iade edilecektir. </p></div>
                                <div className="payment-block">
                                    <div className="payment-total-item"><h5>ÖDEME TUTARI <p>Bu tutar iade edilecektir.</p></h5><span>{PAYZEE_AMOUNT.substring(0,1)+"."+PAYZEE_AMOUNT.substring(1,4)} TL</span></div>
                                    <Form onSubmit={this.submit}
                                          validate={validate}
                                          initialValues={{"fullName":"","card":"","cvv":"","month":"","year":""}}
                                          render={({handleSubmit,focus}) => (
                                              <form onSubmit={handleSubmit} id="payment">
                                                  <div className="form-block">
                                                      <div className="input-block">
                                                          <Field
                                                              name={"fullName"}
                                                              type="text"
                                                              label="AD SOYAD *"
                                                              placeholder="Ad Soyad"
                                                              component={TextInputComponent}
                                                              err_txt="Ad Soyad Giriniz"
                                                              parse={checkLetter}
                                                          />
                                                          <Field
                                                              name={"card"}
                                                              type="text"
                                                              label="KART NUMARASI *"
                                                              placeholder="xxxx xxxx xxxx xxxx"
                                                              component={TextInputComponent}
                                                              err_txt="Kart Numarası Giriniz"
                                                              parse={normalizeCard}
                                                          />
                                                          <Field
                                                              name={"cvv"}
                                                              type="text"
                                                              label="CVV *"
                                                              placeholder="xxx"
                                                              component={TextInputComponent}
                                                              err_txt="CVV Giriniz"
                                                              maxLength={3}
                                                              parse={checkNumber}
                                                          />
                                                          <div className="input-block last-use">
                                                              <Field
                                                                  name={"month"}
                                                                  type="text"
                                                                  label="SON KULLANMA TARİHİ *"
                                                                  placeholder=""
                                                                  component={TextInputComponent}
                                                                  err_txt="Ay Bilgisi Giriniz"
                                                                  maxLength={2}
                                                                  format={checkMonth}
                                                                  formatOnBlur={true}
                                                                  onChangeFunc={this.digitController}
                                                              />
                                                              <p>/</p>
                                                              <Field
                                                                  name={"year"}
                                                                  type="text"
                                                                  id="year"
                                                                  label=""
                                                                  placeholder=""
                                                                  component={TextInputComponent}
                                                                  err_txt="Yıl Bilgisi Giriniz"
                                                                  maxLength={2}
                                                              />
                                                          </div>
                                                          <div className="text-item">
                                                              <p>*Honda Türkiye A.Ş kesinlikle kredi kartı bilgilerinizi görüntülemez
                                                                  ve kaydetmez. Kredi kartı bilgilerinizle güvenli ödeme için yüksek
                                                                  güvenlikli ödeme kanalına yönlendirileceksiniz.</p>
                                                          </div>
                                                          <Field
                                                              type="checkbox"
                                                              name={"is_subscribed3"}
                                                              text={"Rezervasyon sözleşmesini okudum anladım."}
                                                              component={CheckBoxFieldComponent}
                                                              link={LEGAL_WARNING}
                                                              openReservationLb={this.openReservationLb}
                                                          />
                                                      </div>
                                                      <div className="btn-block">
                                                          <div onClick={this.goBack}> <button type={"button"} className="btn-item">GERİ</button> </div>
                                                          <div className="red-btn"  > <button type={"submit"} form="payment" className="red-btn-item">ÖDEME YAP</button></div>
                                                      </div>
                                                  </div>
                                              </form>
                                          )}>
                                    </Form>
                                </div>


                        </div>
                    </div>
                            :
                            <LoaderComponent/>
                    }
                    {
                        this.state.reservation&&
                        <ReservationLbComponent closeLightBox={this.closeLightBox} />
                    }
                    {
                        this.state.error&&
                        <PaymentErrorLbComponent closeLightBox={this.closeLightBox}
                        />
                    }
                    {
                        this.state.stockErr &&
                        <StockErrorLbComponent closeLightBox={this.closeLightBox}/>
                    }
                    {
                        this.state.treeDSecure &&
                        <ThreeDSecureLbComponent data={this.state.data}/>
                    }
                    {
                        this.state.isLoaderShowing && <LoaderComponent/>
                    }
            </div>
        );
    }
}
const mapStateToProps=(state)=>({
    selectedModel: state.personelReducer.selectedModel ? state.personelReducer.selectedModel : {},
    personalData: state.commonReducer.personalInfoFormData || {},
    transactionId: state.commonReducer.transactionId,
    summarySelectedModel: state.personelReducer.selectedModel ? state.personelReducer.selectedModel : null,
    selectedColor: state.userSelectionReducer.selectedColor? state.userSelectionReducer.selectedColor :null
})
export default connect(mapStateToProps)(PaymentPage);
