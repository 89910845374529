import React, {Component} from 'react';
import SummaryVehicleComponent from "../components/summary/summaryVehicleComponent";
import {connect} from 'react-redux';
import {getTransactionInfoAction} from '../actions';
import {PAYZEE_AMOUNT} from "../constants/const";
import {changeDocumentMetas} from "../helper";

class PaymentResultPage extends Component {
   titleValue=null;
   titleDesc=null;
   constructor() {
      super();
      changeDocumentMetas(this.titleValue,this.titleDesc)
   }
   componentDidMount() {
      this.props.dispatch(getTransactionInfoAction(this.props.match.params.transactionId))
      changeDocumentMetas(this.titleValue,this.titleDesc)
      /*if(typeof window !== "undefined"){
         this.setDataLayer()
      }*/
   }
   componentDidUpdate(prevProps) {
      if(!prevProps.selectedModel && typeof window !== "undefined" && prevProps.selectedModel !== this.props.selectedModel){
         this.setDataLayer()
      }
   }

   setDataLayer = () => {
     if(!window.dataLayer) window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
         'event': 'eec.purchase',
         'ecommerce': {
            'purchase': {
               'actionField': {
                  'id': this.props.transactionId || window.location.pathname.split('/')[window.location.pathname.split('/').length-1],
                  'revenue': PAYZEE_AMOUNT,
                  // 'coupon': "{{purchase_cupon}}"
               },
               'products': [{
                  'name': this.props.selectedModel.model,
                  'id': this.props.selectedModel.vehicle_id
               }]
            }
         }
      });
   }

   render() {
      return (
          <div className="payment-result-root">
             <div className="payment-result-wrapper safe-area">
                <SummaryVehicleComponent/>
                <div className="payment-container">
                   <div className="title-block"><h1>Teşekkürler</h1>
                      <p>{PAYZEE_AMOUNT.substring(0, 1) + "." + PAYZEE_AMOUNT.substring(1, 4)} TL Rezervasyon ücreti
                         kartınızdan tahsil edilmiştir. Bu tutar iade edilecektir.</p>
                   </div>
                   <div className="title-block"><h2>Şimdi ne yapmalıyım?</h2>
                      <p>Kısa bir süre içinde Honda bayisinden bir yetkili tarafından tüm detaylar için telefon ile
                         aranacaksınız.</p>
                   </div>
                   <a href="https://honda.com.tr/" target="_blank" rel="noreferrer" className="red-btn-item">honda.com.tr’ye
                      Dön</a>

                </div>
             </div>
          </div>
      );
   }
}
const mapStateToProps=(state)=>({
   selectedModel:state.personelReducer.selectedModel,
   transactionId:state.commonReducer.transactionId || null
})
export default connect(mapStateToProps)(PaymentResultPage);
