import React, {Component} from "react";
import LazyLoad from "react-lazyload";
import {SELECT_VERSION} from "../../constants/routePaths";
import {NavLink} from "react-router-dom";
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";


class ModelItemComponent extends Component {



   render() {
      let {name, img, link} = this.props;
      return (
          <div className="model-list-item">
             <NavLink to={SELECT_VERSION.replace(":modelLink", link)} className="list-item">
                {
                   img &&
                   <div className="img-item">
                      <LazyLoad>
                         <picture>
                            <source
                                srcSet={`${ASSETS}${img.substring(0, img.lastIndexOf("."))}.webp 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x.webp 2x`}/>
                            <source
                                srcSet={`${ASSETS}${img} 1x, ${ASSETS}${img.substring(0, img.lastIndexOf("."))}_2x${img.substring(img.lastIndexOf("."))} 2x`}/>

                            <img  src={ASSETS + img} alt={name}/>
                         </picture>
                      </LazyLoad>
                   </div>
                }
                <h2>{name}</h2>
                {/* <p>Bu modelin şu an için online satışı
                   bulunmamaktadır. Size en yakın
                   bayimizle iletişime geçebilirsiniz.</p> */}
             </NavLink>
          </div>
      )
   }
}

export default connect()(ModelItemComponent);
