import React, {Component} from 'react';
import {matchPath} from 'react-router-dom';
import {
   PAYMENT_STAGE_WITHOUT_PARAM,
   PERSONAL_INFO_WITHOUT_PARAM,
   SELECT_DESIGN,
} from "../constants/routePaths";
import ChangeLightBoxComponent from "../components/lightBox/changeLightBoxComponent";
import {connect} from "react-redux";
import PageService from "../pageServices/pageService";
import SummaryButtonComponent from '../components/layout/summaryButtonComponent';
import SummaryVehicleComponent from '../components/summary/summaryVehicleComponent';
import SummaryPersonInfoComponent from '../components/summary/summaryPersonInfoComponent';
import {breadcrumbArr} from "../constants/const";
import {getBreadCrumbLink} from "../actions";
import {changeDocumentMetas} from "../helper";

class OrderSummeryPage extends Component {
   titleValue="Sipariş Özeti | Honda Online";
   titleDesc="Honda otomobilinizin rezervasyon bilgilerini son kez gözden geçirin. Hata olduğunu düşünüyorsanız bir önceki adıma geri dönerek bilgilerinizi düzeltebilirsiniz.";
   constructor(props) {
      super(props);
      this.pageService = new PageService(props.location.pathname);
      let urlParams = matchPath(this.props.location.pathname, {
         path: SELECT_DESIGN,
         exact: false,
         strict: false
      });
      this.pageService.getData(this.props.dispatch, urlParams);
      if (props.breadCrumbLinks) {
         this.setBreadCrumb(props)
      }
      changeDocumentMetas(this.titleValue,this.titleDesc)
   }

   setBreadCrumb = (props) => {
      let summaryBreadcrumb = [...breadcrumbArr]
      summaryBreadcrumb.forEach((item) => {
         // item.link = null;
         item.isActive = false;
      })
      summaryBreadcrumb[4].isActive = true
      if (props.breadCrumbLinks[2].link) {
         summaryBreadcrumb[3].link = PERSONAL_INFO_WITHOUT_PARAM + props.breadCrumbLinks[2].link.substring(props.breadCrumbLinks[2].link.lastIndexOf("/") + 1, props.breadCrumbLinks[2].link.length)
      }
      this.props.dispatch(getBreadCrumbLink(summaryBreadcrumb))
   }
   pageService;
   state = {
      isOpen: false
   }
   setDataLayer = () => {
      if(!window.dataLayer) window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
         'event': 'eec.checkout_ozet',
         'ecommerce': {
            checkout: {
               actionField: {
                  step: 5
               },
               products: [{
                  'order_summary': 'Sipariş Özetiniz'
               }]
            }
         }
      });
   }

   componentDidMount() {
      if (typeof window !== 'undefined') this.setDataLayer()
   }

   componentWillUnmount() {
      if (typeof window !== "undefined") {
         document.body.classList.remove('no-scroll');
      }

   }

   toggleLightBox = () => {
      if (typeof window !== "undefined") {
         this.setState({
            isOpen: !this.state.isOpen
         }, () => {
            if (this.state.isOpen) {
               document.body.classList.add("no-scroll");
            } else {
               document.body.classList.remove('no-scroll');
            }
         })
      }
   }


   render() {
      let {selectedColor} = this.props;
      return (
          <div className="order-summery-root">
             {
                selectedColor &&
                <SummaryButtonComponent modelLink={this.props.selectedColor.link}
                                        backLink={`${PERSONAL_INFO_WITHOUT_PARAM}${selectedColor.link}`}
                                        haveToEmpty={true} link={PAYMENT_STAGE_WITHOUT_PARAM}
                                        history={this.props.history}/>

             }
             <div className="order-summery-container safe-area">
                <SummaryVehicleComponent toggleLightBox={this.toggleLightBox}/>
                <SummaryPersonInfoComponent/>
                {
                   this.state.isOpen &&
                   <ChangeLightBoxComponent isOpen={this.state.isOpen}
                                            history={this.props.history}
                                            backward={this.toggleLightBox}

                   />

                }
             </div>

          </div>
      );
   }
}

const mapStateToProps = (state) => ({
   breadCrumbLinks: state.commonReducer.breadCrumbLinks,
   selectedColor: state.userSelectionReducer.selectedColor ? state.userSelectionReducer.selectedColor : null
})
export default connect(mapStateToProps)(OrderSummeryPage);
