import React, {Component} from 'react';
import {Field, Form} from "react-final-form";
import TextInputComponent from "../formFields/textInputComponent";
import {checkLetter, checkTcNo, dataStandardized, normalizePhone, scrollToFirstError} from "../../helper";
import CheckBoxFieldComponent from "../formFields/checkBoxFieldComponent";
import RecaptchaFieldComponent from "../formFields/recaptchaFieldComponent";
import DeliveryMethodComponent from "../selectCar/deliveryMethodComponent";
import SelectBoxComponent from "../formFields/selectBoxComponent";
import TextAreaComponent from "../formFields/textAreaComponent";
import {getHtmlHeight, isFormDirtyAction, saveReservationAction} from "../../actions";
import {connect} from "react-redux";
import {ASSETS} from "../../constants/paths"

const validate = (values, param) => {
   const errors = {};
   if (!values || !values.email || values.email === "" || !/^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i.test(values.email)) {
      errors.email = "E-posta giriniz.";

   }
   if (!values.phone || (values.phone && values.phone.length !== 15)) {
      errors.phone = "Telefon numaranızı giriniz ";
   }
   if (!values || !values.tckn || values.tckn === "") {
      errors.tckn = "Tc Kimlik Numarası giriniz";
   }
   if (values.tckn && values.tckn.trim() !== "") {
      let pTCKimlik = values.tckn;
      if (pTCKimlik.length < 11) {
         errors.tckn = "Tc Kimlik Numarası giriniz ";

      } else if (pTCKimlik.substring(0, 1) === "0") {
         errors.tckn = "Tc Kimlik Numarası giriniz ";
      } else {
         let toplam1 = parseInt(pTCKimlik[0].toString(), 10) + parseInt(pTCKimlik[2].toString(), 10) + parseInt(pTCKimlik[4].toString(), 10) + parseInt(pTCKimlik[6].toString(), 10) + parseInt(pTCKimlik[8].toString(), 10);
         let toplam2 = parseInt(pTCKimlik[1].toString(), 10) + parseInt(pTCKimlik[3].toString(), 10) + parseInt(pTCKimlik[5].toString(), 10) + parseInt(pTCKimlik[7].toString(), 10);
         let sonuc = ((toplam1 * 7) - toplam2) % 10;
         if (sonuc.toString() !== pTCKimlik[9].toString()) {
            errors.tckn = "Tc Kimlik Numarası giriniz ";
         }
         let toplam3 = 0;
         for (let i = 0; i < 10; i++) {
            toplam3 += parseInt(pTCKimlik[i].toString(), 10)
         }
         if ((toplam3 % 10).toString() !== pTCKimlik[10].toString()) {
            errors.tckn = "Tc Kimlik Numarası giriniz ";
         }
      }
   }
   if (!values.name || values.name.trim() === "") {
      errors.name = "isim Giriniz";
   }

   if (!param) {
      if (!values || !values.city || values.city === "") {
         errors.city = "İl seçiniz";
      }
      if (!values || !values.district || values.district === "") {
         errors.district = "İlçe seçiniz";
      }
      if (!values.address || values.address.length === "") {
         errors.address = true;
      }
   }else{
      if (!values || !values.dealer_city || values.dealer_city === "") {
         errors.dealer_city = "İl seçiniz";
      }
      if (!values || !values.dealer || values.dealer === "") {
         errors.dealer = "Bayi seçiniz";
      }

   }
   if (!values || !values.is_subscribed || values.is_subscribed === false) {
      errors.is_subscribed = true;
   }
   /*if (!values || !values.is_subscribed2 || values.is_subscribed2 === false) {
      errors.is_subscribed2 = true;
   }*/
   if(!values.recaptcha_code || values.recaptcha_code.length===0){
      errors.recaptcha_code=true
   }
   return errors;

};
class PersonalFormComponent extends Component {
   state = {
      isListen: "",
      isActive: true,
      isLoaderShowing:false,
      smsModalShowing:false
   }
   isDirty=false;
   toggleMenu = (event) => {
      if (typeof window !== "undefined"){
         this.props.dispatch(getHtmlHeight(document.documentElement.offsetHeight))
      }
      if (event === "address") {
         this.setState({
            isListen: event,
            isActive: false
         })

      } else {
         this.setState({
            isListen: event,
            isActive: true
         })
      }

   }
   submit = (values) => {
      this.setDataLayer(values)
      this.props.handleLoader()
      //TODO: Clinet Id GTM'den alinacak.
      let nameArr = values.name.split(" ");
      this.props.dispatch(saveReservationAction(
          {
             name: nameArr.splice(0, nameArr.length - 1).join(" "),
             surname: nameArr[nameArr.length - 1],
             phone_number: `0${values.phone.replace("(", "").replace(")", "").split(" ").join("")}`,
             email: values.email,
             address: values.address?`${values.address} ${values.district.label}`:null,
             tckno: values.tckn,
             city: values.dealer_city ? values.dealer_city.label : values.city.label,
             vehicle_id: this.props.selectedModel.vehicle_id,
             model_id: this.props.selectedModel.vehicle_id,
             color_id: this.props.selectedColor.id,
             dealer_code: values.dealer ? values.dealer.value : values.district.value,
             delivery_type: this.state.isActive ? "dealer_location" : "customer_location",
             recaptcha:values.recaptcha_code,
             model_img: this.props.selectedColor && this.props.selectedColor.detail && this.props.selectedColor.detail.gallery_images ? `${window.location.origin}${ASSETS}${JSON.parse(this.props.selectedColor.detail.gallery_images)[0]}`:null
          },
          (res) => {
             if(res){
                this.props.setProps(res,values)

             }else{
                this.props.handleLoader()
             }
          }
      ))

   }
   setDataLayer = (values) => {
     if(!window.dataLayer) window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
         'event': 'eec.checkout_teslimat',
         'ecommerce': {
            checkout: {
               actionField: {
                  step: 4,
                  option: values.dealer_city ? 'Bayiden Teslim' : 'Adrese Teslim'
               },
               products: [{
                  'form_dealer_city': values.dealer_city ? values.dealer_city.label : values.city.label,
                  'form_dealer_district':  values.district ? values.district.label : '',
                  'form_dealer': values.dealer ? values.dealer.label : ''
               }]
            }
         }
      });
   }
   getFormVal=(val)=>{
      /*todo: dirty değeri başka nasıl alınabilir? console'da warning veriyor*/
      if(this.isDirty !== val){
         this.props.dispatch(isFormDirtyAction(val))
      }

      this.isDirty=val
   }
   render() {
      let{personalData,dealers,cities}=this.props;
      return (
          <Form
              onSubmit={(value)=>{
                 this.submit(value)
              }}
              validate={(values) => validate(values, this.state.isActive)}
              render={({ handleSubmit, values, form,dirty ,submitErrors,submitFailed,errors,modifiedSinceLastSubmit}) => {
                 this.getFormVal(dirty)
                 if(submitFailed && !modifiedSinceLastSubmit&& errors) scrollToFirstError(errors)
                 return (
                     <form onSubmit={handleSubmit} id="personalInfo">
                        <div className="form-block">
                           <div className="input-block">
                              <Field
                                  name={"name"}
                                  type="text"
                                  label="AD SOYAD *"
                                  placeholder="Ad Soyad"
                                  component={TextInputComponent}
                                  err_txt="Ad soyad giriniz"
                                  initialValue={personalData.name}
                                  parse={checkLetter}
                                  // validate={validate}
                              />
                              <Field
                                  name={"tckn"}
                                  type="text"
                                  label="TC KİMLİK NUMARASI *"
                                  placeholder="TC Kimlik Numarası"
                                  component={TextInputComponent}
                                  parse={checkTcNo}
                                  err_txt="TC Kimlik Numarası giriniz"
                                  initialValue={personalData.tckn}

                              />

                              <Field
                                  name={"email"}
                                  type="text"
                                  label="E-POSTA *"
                                  placeholder="E-Posta"
                                  component={TextInputComponent}
                                  err_txt="E-posta giriniz"
                                  initialValue={personalData.email}
                              />
                              <Field
                                  name={"phone"}
                                  type="text"
                                  label="TELEFON NUMARASI *"
                                  placeholder="(5xx) xxx xx xx"
                                  component={TextInputComponent}
                                  parse={normalizePhone}
                                  err_txt="Telefon numarası giriniz"
                                  initialValue={personalData.phone}
                              />
                              <Field
                                  type="checkbox"
                                  name={"is_subscribed"}
                                  text="HONDA TÜRKİYE A.Ş. <a href='/kisisel-verilerin-korunmasi' target='_blank' rel='norefferer'>Kişisel Veriler Hakkında Bilgilendirme Metni</a>’ni Okuduğumu Beyan Ederim"
                                  component={CheckBoxFieldComponent}
                                  initialValue={personalData.is_subscribed}
                              />
                              <div className="label-text">
                                 <span>Burada paylaştığınız bilgilerin doğru olduğunu taahhüt edersiniz. Kimlik doğrulama için bildirdiğiniz cep telefonuna sms kod gönderilecektir.</span>
                              </div>

                              <Field
                                  type="checkbox"
                                  name={"is_subscribed2"}
                                  text={"HONDA TÜRKİYE A.Ş. tarafından yukarıdaki bilgilendirme kapsamında reklam ve tanıtım amaçlı elektronik ticari ileti (SMS-E POSTA ve Çağrı) almak istiyorum"}
                                  component={CheckBoxFieldComponent}
                                  initialValue={personalData.is_subscribed2}
                              />
                              <Field name="recaptcha_code"
                                     component={RecaptchaFieldComponent}/>


                           </div>
                        </div>
                        <DeliveryMethodComponent isActive={this.state.isActive} isListen={this.state.isListen} toggle={this.toggleMenu} />
                        <div className="detail-wrapper">
                           {
                              this.state.isActive &&
                              <div className="detail-container">
                                 <Field
                                     name={"dealer_city"}
                                     component={SelectBoxComponent}
                                     options={dataStandardized(dealers, "city_code", "name")}
                                     label="İL SEÇİMİ *"
                                     onChangeFunc={() => {
                                        form.change("dealer", null)
                                     }}
                                     placeholder="İl Seçiniz"
                                     err_txt="İl Seçiniz"
                                     initialValue={personalData.dealer_city}
                                 />
                                 <Field
                                     name={"dealer"}
                                     component={SelectBoxComponent}
                                     options={values.dealer_city ? dataStandardized(dealers.find(dealerItem => dealerItem.city_code === values.dealer_city.value).dealers, "dealer_code", "dealer_name") : []}
                                     label="BAYİ SEÇİMİ *"
                                     placeholder="Bayi Seçiniz"
                                     err_txt="Bayi Seçiniz"
                                     initialValue={personalData.dealer}

                                 />
                              </div>
                           }

                           {
                              this.state.isListen && !this.state.isActive &&
                              <div className="detail-container">
                                 <Field
                                     name={"city"}
                                     component={SelectBoxComponent}
                                     options={dataStandardized(cities, "city_code", "city_name")}
                                     label="İL SEÇİMİ *"
                                     onChangeFunc={() => {
                                        form.change("district", null)
                                     }}
                                     placeholder="İl Seçiniz"
                                     err_txt="İl Seçiniz"
                                     initialValue={personalData.city}
                                 />
                                 <Field
                                     name={"district"}
                                     component={SelectBoxComponent}
                                     options={values.city ? dataStandardized(cities.find(distiricts => distiricts.city_code === values.city.value).distiricts, "city_code", "city_name") : []}
                                     label="İLÇE *"
                                     placeholder="İlçe Seçiniz"
                                     err_txt="İlçe Seçiniz"
                                     initialValue={personalData.district}

                                 />
                                 <Field
                                     name={"address"}
                                     type="textarea"
                                     label="AÇIK ADRES *"
                                     placeholder="Açık adres giriniz"
                                     component={TextAreaComponent}
                                     initialValue={personalData.address}
                                     err_txt="Açık adres giriniz"
                                 />
                                 <div className="text-item">
                                    <p>*Adrese teslimat seçeneği sadece listede görüntülenen il ve ilçelerde geçerlidir.</p>
                                 </div>
                              </div>
                           }
                        </div>
                     </form>
                 )
              }}>
          </Form>
      );
   }
}
const mapStateToProps = state => ({
   personalData: state.commonReducer.personalInfoFormData || {},
   dealers: state.personelReducer.dealers,
   cities: state.personelReducer.cities,
   selectedModel: state.personelReducer.selectedModel || null,
   selectedColor: state.userSelectionReducer.selectedColor
})
export default connect(mapStateToProps) (PersonalFormComponent);
