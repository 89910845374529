import React, {Component} from 'react';

class DeliveryMethodComponent extends Component {
   deliveryMethod='Bayiden Teslim'
   options = [
      "istanbul", "ankara"
   ]
   submit = (values) => {

   }
   setDataLayer = (deliveryMethod) => {

      if(!window.dataLayer) window.dataLayer = window.dataLayer || [];
      if(this.deliveryMethod !== deliveryMethod){
         window.dataLayer.push({
            event: 'ee - checkoutOption',
            ecommerce: {
               checkout_option: {
                  actionField: {
                     step: 4,
                     option: deliveryMethod
                  }
               }
            }
         });
      }
      this.deliveryMethod = deliveryMethod
   }

   render() {
      let {isActive, isListen, toggle} = this.props
      return (
          <div className="delivery-container">
             <div className="title-block">
                <h2>Teslimat Yöntemi</h2>
                <p>Honda otomobilinizi, seçeceğiniz Honda bayisinden teslim alabilir veya
                   adresinize teslim edilmesini sağlayabilirsiniz.</p>
             </div>
             <div className="select-method-block">
                <div className={`method-item ${isActive ? "active" : ""}`}
                     onClick={() => {
                        toggle("dealer")
                        // this.setDataLayer('Bayiden Teslim')
                     }}
                >
                   <p>Bayiden Teslim </p>
                </div>
                <div className={`method-item ${isListen === "address" ? "active" : ""}`}
                     onClick={() => {
                        toggle("address")
                        // this.setDataLayer('Adrese Teslim')
                     }}>
                   <p>Adrese Teslim</p>

                </div>
             </div>
             {/*  {
                    this.state.isActive &&
                    <CustomSelectBoxComponent/>
                }*/}


          </div>
      );
   }
}

export default DeliveryMethodComponent;
