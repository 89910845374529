import React, {Component} from 'react';

import {getBreadCrumbLink, getSelectedColor, isFormDirtyAction,} from "../actions";
import {connect} from "react-redux";
import {matchPath, withRouter} from "react-router-dom";
import {
   PERSONAL_INFO, SELECT_DESIGN_WITHOUT_PARAM, SELECT_CAR_PAGE,
   SELECT_VERSION_WITHOUT_PARAM
} from "../constants/routePaths";
import PageService from '../pageServices/pageService';
import SummaryButtonComponent from '../components/layout/summaryButtonComponent';
import LoaderComponent from '../components/common/loaderComponent';
import SmsLightBoxComponents from '../components/lightBox/smsLightBoxComponents';
import {breadcrumbArr} from "../constants/const";
import PersonalFormComponent from "../components/formsComponent/personalFormComponent";
import NotFoundPage from './notFoundPage';
import {changeDocumentMetas} from "../helper";


class PersonalInfoPage extends Component {
   summaryComponent = React.createRef();
   titleValue="Kişisel Bilgiler ve Teslimat | Honda Online";
   titleDesc="Kişisel bilgilerinizi içeren formu doldurun ve teslimat yönteminizi seçin. Aracınızı bayiden teslim alabilir veya adrese teslimat seçeneği ile kapınıza gelmesini sağlayabilirsiniz.";

   constructor(props) {
      super(props);
      if (props.selectedModel) {
         this.setBreadCrumb(props)
      }
      changeDocumentMetas(this.titleValue,this.titleDesc)
   }

   setBreadCrumb = (props) => {
      let personalInfoBreadcrumb = [...breadcrumbArr]
      personalInfoBreadcrumb.forEach((item) => {
         item.link = null;
         item.isActive = false;
      })
      personalInfoBreadcrumb[3].isActive = true
      personalInfoBreadcrumb[1].link = props.selectedModel && props.selectedModel.model ? SELECT_VERSION_WITHOUT_PARAM + props.selectedModel.model.toLowerCase().split(" ").join("-") : ""
      personalInfoBreadcrumb[2].link = SELECT_DESIGN_WITHOUT_PARAM + props.match.params.detail
      personalInfoBreadcrumb[0].link = SELECT_CAR_PAGE
      this.props.dispatch(getBreadCrumbLink(personalInfoBreadcrumb))
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.selectedModel !== this.props.selectedModel) {
         this.setBreadCrumb(this.props)
         // this.props.dispatch(getSelectedColor(this.props.selectedModel.colors[0]))
      }
      if(!this.props.userSelectedColor && this.props.selectedColor){
         this.props.dispatch(getSelectedColor(this.props.selectedColor.colors[0]))
      }
   }

   pageService;
   state = {
      isListen: "",
      isActive: true,
      isLoaderShowing: false,
      smsModalShowing: false,
      form: null,
   }

   componentDidMount() {
      this.pageService = new PageService(this.props.location.pathname);
      let urlParams = matchPath(this.props.location.pathname, {
         path: PERSONAL_INFO,
         exact: false,
         strict: false
      });
      this.pageService.getData(this.props.dispatch, urlParams);
   }

   setProps = (res, values) => {
      this.setState({
         isLoaderShowing: false,
         smsModalShowing: true,
         transaction_id: res.transaction_id,
         personData: values
      })
   }
   handleLoader = () => {
      this.setState({
         isLoaderShowing: !this.state.isLoaderShowing
      })
   }
   closeSMSModal = () => {
      this.setState({
         smsModalShowing: false,
         transaction_id: null
      })
   }

   componentWillUnmount() {
      if (Object.keys(this.props.personalData).length === 0) {
         this.props.dispatch(isFormDirtyAction(false))
      }
   }



   render() {
      let {dealers, selectedColor, isVehicleExist} = this.props;
      return (
          <div className="personal-info-root">
             {
                selectedColor &&
                <SummaryButtonComponent modelLink={this.props.match.params.detail}
                                        backLink={`${SELECT_DESIGN_WITHOUT_PARAM}${selectedColor.colors[0].link}`}
                                        personalInfo={true} history={this.props.history}/>
             }
             {
                isVehicleExist !== false &&
                <div className="personal-info-container">
                   <div className="title-block">
                      <h1>Kişisel Bilgiler</h1>
                      <p>Lütfen kişisel bilgilerinizi eksiksiz doldurun ve ilerlemeden önce kontrol edin.</p>
                   </div>
                   {
                      dealers &&
                      <PersonalFormComponent handleLoader={this.handleLoader} setProps={this.setProps}/>
   
                   }
                </div>
                
             }

             {
                (isVehicleExist!==false && (this.state.isLoaderShowing || !dealers)) && <LoaderComponent/>
             }
             {
                isVehicleExist === false && <NotFoundPage/>
             }
             {
                this.state.smsModalShowing && <SmsLightBoxComponents
                    personData={this.state.personData}
                    history={this.props.history} transaction_id={this.state.transaction_id}
                    closeSMSModal={this.closeSMSModal}/>
             }

          </div>
      );
   }
}

const mapStateToProps = (state, ownProps) => ({
   personalData: state.commonReducer.personalInfoFormData || {},
   dealers: state.personelReducer.dealers,
   cities: state.personelReducer.cities,
   selectedModel: state.personelReducer.selectedModel || null,
   userSelectedColor: state.userSelectionReducer.selectedColor || null,
   selectedColor: state.commonReducer.vehicleDetailData && state.commonReducer.vehicleDetailData[ownProps.match.params.detail] ? state.commonReducer.vehicleDetailData[ownProps.match.params.detail].selectedModel : null,
   isVehicleExist: state.commonReducer.vehicleDetailData && state.commonReducer.vehicleDetailData[ownProps.match.params.detail]
})
export default withRouter(connect(mapStateToProps)(PersonalInfoPage));
