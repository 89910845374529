import React, {Component} from 'react';
import LazyLoad from "react-lazyload";
import {ASSETS} from '../constants/paths';
import {changeDocumentMetas} from "../helper";

class HomePage extends Component {
    titleValue="Nasıl Alırım | Honda Online";
    titleDesc="Honda’nızı dilediğiniz yerden, sadece 5 adımda nasıl satın alacağınızı öğrenin.";
    constructor() {
        super();
        changeDocumentMetas(this.titleValue,this.titleDesc)
    }



    render() {

        return (
            <div className="home-root ">
                <div className="title-container">
                    <h1>Nasıl Alırım</h1>
                </div>
                <div className="double-content-container">
                    <div className="content-block">
                        <div className="text-block">
                            <div className="number-block">
                                <span>1</span>
                            </div>
                            <div className="explain-block">
                                <h3>Honda Modelinizi Seçin</h3>
                                <p>Size uygun Honda modelini seçin. </p>
                            </div>
                        </div>
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source media="(min-width: 769px)"
                                            srcSet={`${ASSETS + "/img/homepage-image-1.webp"} 1x, ${ASSETS + "/img/homepage-image-1_2x.webp"} 2x `}
                                            type="image/webp">
                                    </source>
                                    <source media="(min-width: 769px)"
                                            srcSet={`${ASSETS + "/img/homepage-image-1.png"} 1x, ${ASSETS + "/img/homepage-image-1_2x.png"} 2x `}
                                            type="image/png">
                                    </source>
                                    <source media="(max-width: 768px)"
                                            srcSet={`${ASSETS + "/img/homepage-mobile-image-1.webp"} 1x, ${ASSETS + "/img/homepage-mobile-image-1_2x.webp"} 2x `}
                                            type="image/webp">

                                    </source>
                                    <source media="(max-width: 768px)"
                                            srcSet={`${ASSETS + "/img/homepage-mobile-image-1.png"} 1x, ${ASSETS + "/img/homepage-mobile-image-1_2x.png"} 2x `}
                                            type="image/png">
                                    </source>
                                     <img  src={ASSETS + "/img/homepage-image-1.png"} alt="Honda Modelinizi Seçin"/>
                                </picture>
                            </LazyLoad>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-2.webp"} 1x, ${ASSETS + "/img/homepage-image-2_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-2.png"} 1x, ${ASSETS + "/img/homepage-image-2_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <img  src={ASSETS + "/img/homepage-image-2.png"} alt="Size En Uygun Versiyonu Bulun"/>
                                </picture>
                            </LazyLoad>
                        </div>
                        <div className="text-block">
                            <div className="number-block">
                                <span>2</span>
                            </div>
                            <div className="explain-block">
                                <h3>Size En Uygun Versiyonu Bulun</h3>
                                <p>Honda otomobilinizin motor, versiyon, model yılı, renk gibi özelliklerini seçin. </p>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="text-block">
                            <div className="number-block">
                                <span>3</span>
                            </div>
                            <div className="explain-block">
                                <h3>Formu Doldurun ve Teslimat Yöntemi Seçin</h3>
                                <p>Kişisel bilgilerinizi içeren formu doldurun ve teslimat yönteminizi seçin. Aracınızı
                                    bayiden teslim alabilir veya adrese teslimat seçeneği ile kapınıza gelmesini
                                    sağlayabilirsiniz. </p>
                            </div>
                        </div>
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-3.webp"} 1x, ${ASSETS + "/img/homepage-image-3_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-3.png"} 1x, ${ASSETS + "/img/homepage-image-3_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <img  src={ASSETS + "/img/homepage-image-3.png"} alt="Formu Doldurun"/>
                                </picture>
                            </LazyLoad>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-4.webp"} 1x, ${ASSETS + "/img/homepage-image-4_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-4.png"} 1x, ${ASSETS + "/img/homepage-image-4_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <img  src={ASSETS + "/img/homepage-image-4.png"} alt="Ön Ödemenizi Yapın"/>
                                </picture>
                            </LazyLoad>
                        </div>
                        <div className="text-block">
                            <div className="number-block">
                                <span>4</span>
                            </div>
                            <div className="explain-block">
                                <h3>Ön Ödemenizi Yapın</h3>
                                <p>Rezervasyonunuzu tamamlamak için güvenli bir şekilde 2.000 TL ödemenizi yapın.
                                    Rezervasyonunuzdan kısa bir süre sonra bayimiz sizinle iletişime geçecektir. </p>
                            </div>
                        </div>
                    </div>
                    <div className="content-block">
                        <div className="text-block">
                            <div className="number-block">
                                <span>5</span>
                            </div>
                            <div className="explain-block">
                                <h3>Aracınızı Bayiden Teslim Alın veya Kapınıza Teslim Edelim</h3>
                                <p>Ödemeniz tamamlandıktan ve yetkili ile konuştuktan sonra aracınızı seçtiğiniz
                                    teslimat yöntemine uygun şekilde teslim alın. </p>
                            </div>
                        </div>
                        <div className="image-block">
                            <LazyLoad>
                                <picture>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-5.webp"} 1x, ${ASSETS + "/img/homepage-image-5_2x.webp"} 2x `}
                                        type="image/webp"/>
                                    <source
                                        srcSet={`${ASSETS + "/img/homepage-image-5.png"} 1x, ${ASSETS + "/img/homepage-image-5_2x.png"} 2x `}
                                        type="image/jpeg"/>
                                    <img  src={ASSETS + "/img/homepage-image-5.png"} alt="Bayiden Teslim Alın"/>
                                </picture>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
                <div className="bottom-text-area">
                    <div className="head-text-block">
                        <h2>Nasıl İptal Edebilirim?</h2>
                    </div>
                    <div className="text-block">
                        <p>Rezervasyonunuzu iptal etmek için herhangi bir işlem yapmanıza gerek yoktur. 3 gün içinde yetkili satıcı tarafından satış işlemi gerçekleşmeyen tüm rezervasyonlar otomatik olarak iptal edilecek, rezervasyon bedelinin geri ödemesi gerçekleştirilecektir.</p>

                    </div>

                </div>
            </div>
        );
    }
}

export default HomePage;