import React, {Component} from 'react';
import CustomSelectBoxComponent from "../components/formFields/customSelectBoxComponent";
import {getBreadCrumbLink, getSelectedColor, getUserVersionDetail} from "../actions";
import CustomColorSelectComponent from "../components/formFields/customColorSelectComponent";
import {
   PERSONAL_INFO_WITHOUT_PARAM, SELECT_CAR_PAGE,
   SELECT_DESIGN,
   SELECT_VERSION_WITHOUT_PARAM
} from "../constants/routePaths";
import {matchPath} from "react-router-dom";
import PageService from "../pageServices/pageService";
import {connect} from "react-redux";
import SliderComponent from "../components/slider/sliderComponent";
import SliderImgComponent from "../components/slider/sliderImgComponent";
import LoaderComponent from "../components/common/loaderComponent";
import SummaryButtonComponent from "../components/layout/summaryButtonComponent";
import {breadcrumbArr, PAYZEE_AMOUNT} from "../constants/const";
import NotFoundPage from './notFoundPage';
import {changeDocumentMetas} from "../helper";


class SelectDesignPage extends Component {
   titleValue="Versiyon ve Opsiyon Seç | Honda Online";
   titleDesc="Motor, versiyon, model yılı, renk gibi özellikleri seçerek size en uygun Honda otomobili bulun.";
   pageService;
   sliderComponent = React.createRef()
   initModelLink;

   constructor(props) {
      super(props);
      this.initModelLink = props.match.params.detail
      this.getData()
      if (props.selectedModel) {

         props.dispatch(getSelectedColor(props.selectedModel.colors[0]))
         this.setBreadCrumb(props)
      }
      changeDocumentMetas(this.titleValue,this.titleDesc)

   }

   /*submit = (values) => {
      console.log("values", values);
      this.props.dispatch(getDetailInfoFormData(values))
   }*/
   getData = () => {
      this.pageService = new PageService(this.props.location.pathname);
      let urlParams = matchPath(this.props.location.pathname, {
         path: SELECT_DESIGN,
         exact: false,
         strict: false
      });
      this.pageService.getData(this.props.dispatch, urlParams);
   }

   componentDidMount() {
      if (this.props.modelDetail && this.props.modelDetail.length > 0 && this.props.modelDetail[0] && this.props.modelDetail[0].engineName) {
         this.props.dispatch(getUserVersionDetail({
            engine: this.props.modelDetail[0],
            year: this.props.modelDetail[0].years[0]
         }))
      }
   }

   setDataLayer = () => {
      if (this.props.vehicleDetailData[this.props.match.params.detail] || (this.props.vehicleDetailData[this.initModelLink] && this.props.vehicleDetailData[this.initModelLink].selectedModel)) {
         if (!window.dataLayer) window.dataLayer = window.dataLayer || []
         window.dataLayer.push({
            'event': 'eec.checkout_tasarim',
            'ecommerce': {
               checkout: {
                  actionField: {
                     step: 3,
                     option: this.props.vehicleDetailData[this.props.match.params.detail] ? this.props.vehicleDetailData[this.props.match.params.detail].selectedModel.colors[0].name : this.props.vehicleDetailData[this.initModelLink].selectedModel.colors[0].name
                  },
                  products: [{
                     'name': this.props.vehicleDetailData[this.props.match.params.detail] ? this.props.vehicleDetailData[this.props.match.params.detail].selectedModel.model : this.props.vehicleDetailData[this.initModelLink].selectedModel.model,
                     'id': this.props.vehicleDetailData[this.props.match.params.detail] ? this.props.vehicleDetailData[this.props.match.params.detail].selectedModel.vehicle_id : this.props.vehicleDetailData[this.initModelLink].selectedModel.vehicle_id,
                     'price': this.props.vehicleDetailData[this.props.match.params.detail] ? this.props.vehicleDetailData[this.props.match.params.detail].selectedModel.price : this.props.vehicleDetailData[this.initModelLink].selectedModel.price,
                     'category': 'Otomobil',
                     'variant': this.props.vehicleDetailData[this.props.match.params.detail] ? this.props.vehicleDetailData[this.props.match.params.detail].selectedModel.engine.split(' ')[0] + " " + this.props.vehicleDetailData[this.props.match.params.detail].selectedModel.version : this.props.vehicleDetailData[this.initModelLink].selectedModel.engine.split(' ')[0] + " " + this.props.vehicleDetailData[this.initModelLink].selectedModel.version,
                     'dimension19': PAYZEE_AMOUNT.substring(0, 1) + "." + PAYZEE_AMOUNT.substring(1, 4)
                  }]
               }
            }
         });
      }

   }

   setBreadCrumb = (props) => {
      let designBreadcrumb = [...breadcrumbArr]
      designBreadcrumb.forEach((item) => {
         item.link = null;
         item.isActive = false;
      })
      designBreadcrumb[2].isActive = true
      /*designBreadcrumb[2].link=null
      designBreadcrumb[3].isActive=false*/
      if (props.vehicleDetailData[props.match.params.detail] || (props.vehicleDetailData[this.initModelLink] && props.vehicleDetailData[this.initModelLink].selectedModel)) {
         designBreadcrumb[1].link = SELECT_VERSION_WITHOUT_PARAM + (props.vehicleDetailData[props.match.params.detail] && props.vehicleDetailData[props.match.params.detail].selectedModel ? props.vehicleDetailData[props.match.params.detail].selectedModel.modelLink : props.vehicleDetailData[this.initModelLink].selectedModel.modelLink)
      }
      designBreadcrumb[0].link = SELECT_CAR_PAGE

      this.props.dispatch(getBreadCrumbLink(designBreadcrumb))
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.modelDetail !== this.props.modelDetail && this.props.modelDetail[0] && this.props.modelDetail[0].years) {
         this.props.dispatch(getUserVersionDetail({
            engine: this.props.modelDetail[0],
            year: this.props.modelDetail[0].years[0]
         }))
         this.setBreadCrumb(this.props)
      }
      if (prevProps.location.pathname !== this.props.location.pathname || JSON.stringify(prevProps.selectedModel) !== JSON.stringify(this.props.selectedModel)) {
         this.setBreadCrumb(this.props)
      }
      if (prevProps.location.pathname !== this.props.location.pathname) {
         this.getData()
      }
      if (this.props.selectedModel && !prevProps.selectedModel && prevProps.selectedModel !== this.props.selectedModel) {
         this.props.dispatch(getSelectedColor(this.props.selectedModel.colors[0]))
      }
      if (!prevProps.vehicleDetailData[this.initModelLink] && prevProps.vehicleDetailData[this.initModelLink] !== this.props.vehicleDetailData[this.initModelLink]) {
         this.setDataLayer()
      }
   }

   resetVal = () => {
      this.sliderComponent.current.resetTranslateVal()
   }

   render() {
      let {version_detail, selectedColor, vehicleDetailData} = this.props
      let selectedModel
      let modelDetail
      let modelExist =vehicleDetailData[this.props.match.params.detail];
      if (vehicleDetailData[this.initModelLink] || vehicleDetailData[this.props.match.params.detail]) {
         selectedModel = vehicleDetailData[this.props.match.params.detail] ? vehicleDetailData[this.props.match.params.detail].selectedModel : vehicleDetailData[this.initModelLink].selectedModel
         modelDetail = vehicleDetailData[this.props.match.params.detail] ? vehicleDetailData[this.props.match.params.detail].modelDetails : vehicleDetailData[this.initModelLink].modelDetails
      }
      return (
          <div className="model-design-root">
             {
                selectedModel &&
                <SummaryButtonComponent modelLink={this.props.match.params.detail} history={this.props.history}
                                        backLink={SELECT_VERSION_WITHOUT_PARAM + selectedModel.modelLink}
                                        link={`${PERSONAL_INFO_WITHOUT_PARAM}${this.props.match.params.detail}`}/>
             }
             {
                (version_detail && selectedModel && modelDetail && modelDetail.length > 0) ?
                    <div className="model-design-wrapper safe-area">
                       <div className="title-block">
                          <h1>Versiyon ve opsiyonlarınızı seçin</h1>
                          <p>Motor seçeneği, versiyon ve renk seçerek Honda otomobilinizi yaratmaya başlayın.</p>
                       </div>

                       <div className="model-design-container">
                          {
                             (selectedColor && selectedColor.detail && selectedColor.detail.gallery_images) &&
                             <SliderComponent ref={this.sliderComponent}>
                                {/*<SliderImgComponent/>*/}
                                {
                                   selectedColor && JSON.parse(selectedColor.detail.gallery_images).map((img, index) =>
                                       <SliderImgComponent img={img} key={index} model={selectedModel.model}/>
                                   )
                                }

                             </SliderComponent>
                          }


                          <div className="detail-wrapper">
                             <div className="name-item">
                                <h2>{selectedModel.model}</h2>
                             </div>
                             <CustomSelectBoxComponent
                                 setReducer={(item) => {
                                    this.props.dispatch(getUserVersionDetail({engine: item, year: item.year[0]}))
                                 }}
                                 options={modelDetail.map(item => item.engineName)}
                             />
                             <CustomSelectBoxComponent
                                 setReducer={(item) => {
                                    this.props.dispatch(getUserVersionDetail({year: item}))
                                 }}
                                 options={version_detail.engine.years.map(year => year.model_year)}
                             />
                             <CustomColorSelectComponent
                                 colors={version_detail.year.colors}
                                 resetVal={this.resetVal}
                                 history={this.props.history}
                             />

                             <div className="price-detail-block">
                                <div className="price-item">
                                   <p>{selectedModel.model}</p>
                                   <span>{version_detail.year.price ? version_detail.year.price.toLocaleString("tr-TR") + " TL" : null}</span>
                                   <p>Tavsiye Edilen Fiyat</p>
                                </div>
                                <div className="price-item">
                                   <p>Rezervasyon için yapılacak ödeme tutarı:</p>
                                   <span>{PAYZEE_AMOUNT.substring(0, 1) + "." + PAYZEE_AMOUNT.substring(1, 4)} TL</span>
                                </div>
                             </div>
                             <ul className="features-block">
                                {
                                   version_detail && version_detail.year.properties.map((item, index) =>
                                       <li key={index}>{item}</li>
                                   )
                                }
                             </ul>
                             <a className="detail-btn" href="https://honda.com.tr/otomobil/modeller/jazz-hibrit"
                                target="_blank" rel="noreferrer">
                                <span>Model detaylarını  incelemek için tıklayın</span>
                                <i>
                                   <svg width={32} height={32} viewBox="0 0 32 32" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M21 17V20C21 20.5523 20.5523 21 20 21H12C11.4477 21 11 20.5523 11 20V12C11 11.4477 11.4477 11 12 11H15"
                                          stroke="#5C5E62" strokeWidth={2} strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                      <path d="M19 9H23M23 9V13M23 9L17 15" stroke="#5C5E62" strokeWidth={2}
                                            strokeLinecap="round" strokeLinejoin="round"/>
                                   </svg>
                                </i>
                             </a>
                          </div>
                       </div>
                    </div>
                    :
                    (
                     modelExist === false ?
                     <NotFoundPage/>
                     :
                     <LoaderComponent/>
                    )

             }

          </div>
      );
   }
}

const mapStateToProps = (state, ownProps) => ({
   selectedModel: state.commonReducer.vehicleDetailData && state.commonReducer.vehicleDetailData[ownProps.match.params.detail] ? state.commonReducer.vehicleDetailData[ownProps.match.params.detail].selectedModel : null,
   // selectedModel: state.personelReducer.selectedModel ? state.personelReducer.selectedModel : null,
   vehicleDetailData: state.commonReducer.vehicleDetailData,
   modelDetail: state.commonReducer.vehicleDetailData && state.commonReducer.vehicleDetailData[ownProps.match.params.detail] ? state.commonReducer.vehicleDetailData[ownProps.match.params.detail].modelDetails : [],
   version_detail: state.userSelectionReducer.version_detail || null,
   selectedColor: state.userSelectionReducer.selectedColor
})

export default connect(mapStateToProps)(SelectDesignPage);
