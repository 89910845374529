import React, { Component } from 'react'
import { connect } from 'react-redux'

class SummaryPersonInfoComponent extends Component {
   render() {
      let { personalData } = this.props;
      return (
         <div>
            {
               Object.keys(personalData).length > 0 &&
               <div className="order-summery-block">
                  <div className="title-block">
                     <h1>Sipariş özetiniz</h1>
                     <p>Honda otomobilinizin rezervasyon bilgilerini son kez gözden geçirin.</p>
                  </div>
                  <div className="personal-info-container">
                     <div className="head-item">
                        <h3>
                           Kişisel Bilgiler
                         </h3>
                     </div>
                     <div className="info-block">
                        <div className="info-item">
                           <p>Ad Soyad:</p>
                           <span>{personalData.name}</span>
                        </div>

                        <div className="info-item">
                           <p>Telefon Numarası:</p>
                           <span>{personalData.phone}</span>
                        </div>
                        <div className="info-item">
                           <p>E-Posta:</p>
                           <span>{personalData.email}</span>
                        </div>
                        <div className="info-item">
                           <p>TC Kimlik Numarası:</p>
                           <span>{personalData.tckn}</span>
                        </div>
                     </div>
                     <div className="head-item">
                        <h3>
                           Teslimat Bilgileri
                         </h3>
                     </div>
                     <div className="info-block delivery-info">
                        <div className="info-item">
                           <p>Teslimat Yöntemi:</p>
                           <span>{!personalData.address ? "Bayiden Teslim" : "Adrese Teslim"}</span>
                        </div>
                        {
                           (personalData.dealer_city || personalData.city) &&
                           <div className="info-item">
                              <p>Şehir:</p>
                              <span>{!personalData.address ? personalData.dealer_city.label : personalData.city.label}</span>
                           </div>
                        }
                        {
                           (personalData.dealer || personalData.district) &&
                           <div className="info-item">
                              <p>{!personalData.address ? "Bayi:" : "İlçe:"}</p>
                              <span>{!personalData.address ? personalData.dealer.label : personalData.district.label}</span>
                           </div>
                        }

                        {
                           personalData.address &&
                           <div className="info-item address">
                              <p>Açık adres:</p>
                              <span>{personalData.address}</span>
                           </div>
                        }


                     </div>


                  </div>
               </div>
            }
         </div>


      )
   }
};


const mapStateToProps = (state, ownProps) => ({
   personalData: state.commonReducer.personalInfoFormData || {}
})

export default connect(mapStateToProps)(SummaryPersonInfoComponent)
