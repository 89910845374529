import React, {Component} from 'react';

import PageService from "../pageServices/pageService";

import {connect} from "react-redux";
import CarVersionComponent from '../components/carVersionComponent';
import {matchPath, withRouter} from "react-router-dom";
import {SELECT_CAR_PAGE, SELECT_VERSION, SELECT_VERSION_WITHOUT_PARAM} from "../constants/routePaths";
import {getBreadCrumbLink, getSelectedColor} from "../actions";
import {breadcrumbArr} from "../constants/const";
import LoaderComponent from "../components/common/loaderComponent";
import NotFoundPage from './notFoundPage';
import {changeDocumentMetas} from "../helper";

const queryString = require('query-string');

class SelectVersionPage extends Component {
   constructor(props) {
      super(props);
      this.pageService = new PageService(props.location.pathname);
      let urlParams = matchPath(this.props.location.pathname, {
         path: SELECT_VERSION,
         exact: false,
         strict: false
      });
      this.pageService.getData(this.props.dispatch, urlParams);
      if(this.props.selectedModel){
         this.setBreadCrumb()
      }
      changeDocumentMetas(this.titleValue,this.titleDesc)
      // this.props.dispatch(getSelectedColor(null))
   }

   setBreadCrumb = (props) => {
      let versionBreadcrumb = [...breadcrumbArr]
      versionBreadcrumb.forEach((item) => {
         item.link = null;
         item.isActive = false;
      })
      versionBreadcrumb[1].isActive = true
      versionBreadcrumb[0].link = SELECT_CAR_PAGE
      this.props.dispatch(getBreadCrumbLink(versionBreadcrumb))
   }
   titleValue="Model Seç | Honda Online";
   titleDesc="Online rezervasyon oluşturmak istediğiniz Honda otomobili seçin.";
   pageService;
   searchObj;
   searchTerm
   state = {
      isActive: false,
      currentQuery: null
   }
   toggleMenu = () => {
      this.setState({
         isActive: !this.state.isActive
      })
   }
   setPrevQuery = () => {
      this.props.history.push(`${SELECT_VERSION_WITHOUT_PARAM}${this.props.match.params.modelLink}?${this.state.currentQuery && this.state.currentQuery.length > 0 ? this.state.currentQuery : ""}`)
      this.toggleMenu()
   }
   getCrntQuery = () => {
      this.setState({
         currentQuery: this.searchTerm || this.props.location.search.split('?')[1]
      })
   }
   setURL = (type, value) => {
      let modifiedValue = value.toLowerCase()
      this.searchObj = queryString.parse(this.props.location.search) || {};
      if (value.indexOf(' ') !== -1) {
         modifiedValue = modifiedValue.split(" ").join("-")
      }

      if (value === "") {
         this.searchObj[type] = undefined
      } else {
         this.searchObj[type] = modifiedValue
      }
      this.searchTerm = queryString.stringify(this.searchObj);
      this.props.history.push(`${SELECT_VERSION_WITHOUT_PARAM}${this.props.match.params.modelLink}?${this.searchTerm.length > 0 ? this.searchTerm : ""}`)
   }
   setModelUrl = (item) => {
      this.props.history.push(SELECT_VERSION_WITHOUT_PARAM + item.link)
   }
   filteredVersion = (versionItem, index) => {
      let searched = queryString.parse(this.props.location.search)
      return (!searched.version || versionItem.version.split(" ").join("-").toLowerCase() === searched.version) && (!searched.year || versionItem.model_year == searched.year) && (!searched.engine || versionItem.engine.split(" ").join("-").toLowerCase() === searched.engine)
   }

   setDataLayer = (name, id, price, category = 'Otomobil', variant) => {
      if (!window.dataLayer) window.dataLayer = window.dataLayer || []
      if(this.props.selectedModel){
         window.dataLayer.push({
            'event': 'eec.checkout_versiyon',
            'ecommerce': {
               'checkout': {
                  'actionField': {
                     'step': 2,
                     'option':this.props.location.search ? queryString.parse(this.props.location.search).version : "VERSİYON Tümü",
                     // 'option':"Tümü"
                  },
                  'products': this.props.selectedModel.versions.filter(this.filteredVersion).map(version => ({
                     name: version.model,
                     id: version.vehicle_id,
                     price: version.price,
                     category: 'Otomobil',
                     // variant: version.engine.split(' ')[0] + ' ' + version.version
                  }))
               }
            }
         });
      }
   }
   setFilterDataLayer = (variant) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
         event: 'ee - checkoutOption',
         ecommerce: {
            checkout_option: {
               actionField: {
                  step: 2,
                  option: variant
               }
            }
         }
      });
   }

   componentDidMount() {
      if (typeof window !== "undefined" && this.props.selectedModel && this.props.selectedModel.versions.length > 0) {
         this.setDataLayer()
      }

   }

   componentDidUpdate(prevProps) {
      if ((!prevProps.selectedModel && prevProps.selectedModel !== this.props.selectedModel)) {
         this.setDataLayer()
         this.setBreadCrumb();
      }
   }

   render() {
      let {selectedModel, models} = this.props;
      let searched = queryString.parse(this.props.location.search)
      return (
          <div className="car-select-root">
             {
                selectedModel ?
                    <div className="car-select-wrapper">
                       <div className={`left-container ${this.state.isActive ? "active" : ""}`}>
                          <div className="filter-block" onClick={() => {
                             this.toggleMenu()
                             this.getCrntQuery()
                          }}>
                             <div className="icon-item">
                                <svg width={12} height={10} viewBox="0 0 12 10" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                   <path
                                       d="M4.49996 7.99989H7.49996C7.69888 7.98795 7.89438 8.05552 8.04347 8.18774C8.19256 8.31995 8.28303 8.50597 8.29496 8.70489C8.3069 8.9038 8.23933 9.0993 8.10711 9.2484C7.9749 9.39749 7.78888 9.48795 7.58996 9.49989H4.49996C4.30105 9.51182 4.10555 9.44425 3.95645 9.31203C3.80736 9.17982 3.7169 8.9938 3.70496 8.79488C3.69303 8.59597 3.7606 8.40047 3.89281 8.25138C4.02503 8.10228 4.21105 8.01182 4.40996 7.99989H4.49996ZM2.99996 4.24989H8.99996C9.19888 4.23795 9.39438 4.30552 9.54347 4.43774C9.69256 4.56995 9.78303 4.75597 9.79496 4.95489C9.8069 5.1538 9.73933 5.3493 9.60711 5.4984C9.4749 5.64749 9.28888 5.73795 9.08996 5.74989H2.99996C2.80105 5.76182 2.60554 5.69425 2.45645 5.56204C2.30736 5.42982 2.2169 5.2438 2.20496 5.04489C2.19303 4.84597 2.2606 4.65047 2.39281 4.50138C2.52503 4.35228 2.71105 4.26182 2.90996 4.24989H2.99996ZM0.749963 0.499885H11.25C11.4489 0.48795 11.6444 0.555522 11.7935 0.687735C11.9426 0.819948 12.033 1.00597 12.045 1.20489C12.0569 1.4038 11.9893 1.5993 11.8571 1.7484C11.7249 1.89749 11.5389 1.98795 11.34 1.99989H0.749963C0.551051 2.01182 0.355544 1.94425 0.206453 1.81204C0.0573615 1.67982 -0.033102 1.4938 -0.0450367 1.29489C-0.0569715 1.09597 0.0106003 0.900466 0.142813 0.751375C0.275027 0.602283 0.461051 0.51182 0.659963 0.499885H0.749963Z"
                                       fill="#5C5E62"/>
                                </svg>
                             </div>
                             <p>Filtrele</p>
                          </div>
                          {
                             models.length > 0 &&
                             <div className="select-input-block select-model">

                                <h3>MODEL</h3>
                                {
                                   models.map((item, key) =>
                                       <div className="radio-item" key={key}>
                                          <label className="radio-label">
                                             <input name={"models"} type="radio"
                                                    onClick={()=>this.setFilterDataLayer(item)}
                                                    value={item.name}
                                                    onChange={() => this.setModelUrl(item)}
                                                    checked={this.props.match.params.modelLink === item.link}
                                             />
                                             {item.name}
                                             <span className="checkmark"/>
                                          </label>
                                       </div>
                                   )
                                }

                             </div>
                          }

                          {
                             selectedModel.allEngines.length !== 1 &&
                             <div className="select-input-block select-engine">
                                <h3>MOTOR</h3>
                                <div className="radio-item">
                                   <label className="radio-label">
                                      <input onClick={()=>this.setFilterDataLayer("MOTOR Tümü")} name="allEngines" type="radio" onChange={() => this.setURL("engine", "")}
                                             checked={!(searched['engine'])}
                                      />
                                      Tümü
                                      <span className="checkmark"/>
                                   </label>
                                </div>
                                {
                                   selectedModel.allEngines.map((item, i) =>
                                       <div className="radio-item" key={i}>
                                          <label className="radio-label">
                                             <input name="allEngines" type="radio"
                                                    onClick={()=>this.setFilterDataLayer(item)}
                                                    onChange={() => this.setURL("engine", item)}
                                                    checked={!!(searched["engine"] === item.split(" ").join("-").toLowerCase())}
                                             />
                                             {item}
                                             <span className="checkmark"/>
                                          </label>
                                       </div>
                                   )
                                }
                             </div>
                          }

                          {
                             selectedModel.allVersions.length !== 1 &&
                             <div className="select-input-block select-version">
                                <h3>VERSİYON</h3>
                                {
                                   <div className="radio-item">
                                      <label className="radio-label">
                                         <input name="allVersions" type="radio" onClick={()=>this.setFilterDataLayer("VERSİYON Tümü")}
                                                onChange={() => this.setURL("version", "")}
                                                checked={!(searched['version'])}
                                         />
                                         Tümü
                                         <span className="checkmark"/>
                                      </label>
                                   </div>
                                }
                                {
                                   selectedModel.allVersions.map((item, i, a) =>
                                       <div className="radio-item" key={i}>
                                          <label className="radio-label">
                                             <input name={"allVersions"} type="radio"
                                                    onClick={()=>this.setFilterDataLayer(item)}
                                                    onChange={() => this.setURL("version", item)}
                                                    checked={!!(searched["version"] === item.split(" ").join("-").toLowerCase())}
                                             />
                                             {item}
                                             <span className="checkmark"/>
                                          </label>
                                       </div>
                                   )
                                }

                             </div>
                          }

                          {
                             selectedModel.allYears.length !== 1 &&
                             <div className="select-input-block select-year">
                                <h3>MODEL YILI</h3>
                                {
                                   <div className="radio-item">
                                      <label className="radio-label">
                                         <input name="allYears" type="radio" onChange={() => this.setURL("year", "")}
                                                checked={!(searched['year'])}
                                         />
                                         Tümü
                                         <span className="checkmark"/>
                                      </label>
                                   </div>
                                }

                                {
                                   selectedModel.allYears.map((item, i) =>
                                       <div className="radio-item" key={i}>
                                          <label className="radio-label">
                                             <input name={"allYears"} type="radio"
                                                    onClick={()=>this.setFilterDataLayer(item)}
                                                    onChange={() => this.setURL("year", item)}
                                                    checked={!!(searched["year"] === item)}
                                             />
                                             {item}
                                             <span className="checkmark"/>
                                          </label>
                                       </div>
                                   )
                                }
                             </div>
                          }

                          <div className="btn-block">
                             <div className="btn-item" onClick={this.setPrevQuery}>VAZGEÇ</div>
                             <div className="red-btn-item" onClick={this.toggleMenu}>UYGULA</div>
                          </div>
                       </div>
                       <div className="select-container">
                          <div className="title-block">
                             <h1>Modelinizi seçin</h1>
                             <p>Online rezervasyon oluşturmak istediğiniz Honda otomobili seçin.</p>
                          </div>
                          <div className="car-select-container">
                             {
                                selectedModel.versions.filter(this.filteredVersion).map((item, key) =>
                                    <CarVersionComponent selectedModel={selectedModel} {...item} key={item.vehicle_id}/>
                                )
                             }
                          </div>
                       </div>

                    </div>
                    :
                    (models.length==0?
                     <LoaderComponent/>
                     :
                     <NotFoundPage/>
                     )
             }

          </div>
      );
   }
}

const mapStateToProps = (state, ownProps) => ({
   selectedModel: state.modelsReducer.models && state.modelsReducer.models.find(model => model.link === ownProps.match.params.modelLink),
   models: state.modelsReducer.models || []
})
export default withRouter(connect(mapStateToProps)(SelectVersionPage));
