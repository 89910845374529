import React, {Component} from 'react';
class CheckBoxFieldComponent extends Component {


   render() {
      const {text, input, meta: {error, touched},openReservationLb} = this.props;

      return (
          <div className="radio-button">
             <div className={`radio-item  ${touched && error ? "error" : ""}  `}>
                <input id={input.name} name="radio" {...input} type={input.type}/>
                <label htmlFor={input.name} className="radio-label">
                   {

                          <span dangerouslySetInnerHTML={{__html: text}} onClick={openReservationLb&&openReservationLb}/>
                   }

                </label>

             </div>
          </div>
      );
   }
}

export default CheckBoxFieldComponent;
