import React, {Component} from 'react';
import {connect} from "react-redux";
import {getSelectedColor} from "../../actions";
import {SELECT_DESIGN_WITHOUT_PARAM} from "../../constants/routePaths";

class CustomColorSelectComponent extends Component {
   setColors=(colorGroup)=>{
      if(this.props.colors.length>1 && this.props.selectedColor.id !== colorGroup.id){
         this.props.dispatch(getSelectedColor(colorGroup))
         // this.props.history.push(SELECT_DESIGN_WITHOUT_PARAM+colorGroup.link)
         this.props.history.replace(SELECT_DESIGN_WITHOUT_PARAM+colorGroup.link)
         this.props.resetVal()
         if (this.props.selectedColor && this.props.selectedColor.id !== colorGroup.id){
            this.setDataLayerColor(colorGroup)
         }
      }
   }
   setDataLayerColor=(color)=>{
      window.dataLayer.push({
         event: 'ee - checkoutOption',
         ecommerce: {
            checkout_option: {
               actionField: {
                  step: 3,
                  option: color.name
               }
            }
         }
      });
   }

   render() {
      let {colors,selectedColor} = this.props
      return (
          <div className="select-colour-block">

             {
                colors && colors.map((clr, index) =>

                    <div className={`select-colour-item ${clr.id && clr.id === selectedColor.id ? "active" : ""} ${colors.length<2 ? "default":""}`}
                         style={{borderColor:clr.detail?clr.detail.color_code:"#FFFF"}} key={index}
                         onClick={() =>{
                            this.setColors(clr)

                         }}>
                       <div style={{backgroundColor:`${clr.detail?clr.detail.color_code:"#FFFF"}`}} className="colour"/>
                    </div>
                )
             }
          </div>
      );
   }
}
const mapStateToProps=state=>({
   modelDetail:state.commonReducer.vehicleDetailData ? state.commonReducer.vehicleDetailData.modelDetails : {},
   selectedColor: state.userSelectionReducer.selectedColor
})
export default connect(mapStateToProps) (CustomColorSelectComponent);
