import React, {Component} from 'react';
import SmallSliderComponent from "./smallSliderComponent";
import {getElementWidth} from "../../helper";

class SliderComponent extends Component {
   sliderBlock = React.createRef();
   sliderContainer= React.createRef();
   itemWidth = 0;
   activeIndex=0
   state = {
      translateValue: 0,
   }


   componentDidMount() {
      if (typeof window !== "undefined") {
         this.sliderBlock.current.addEventListener('touchstart', this.handleTouchStart, false);
         this.sliderBlock.current.addEventListener('touchmove', this.handleTouchMove, false);
         window.addEventListener('resize', this.resizingWindow);
      }
   }
   calculateItem = () => {
      if (this.sliderBlock.current && this.sliderBlock.current.children) {
         this.itemWidth = getElementWidth(this.sliderBlock.current.children[1])
      }
   }
   slideFunction = (direction) => {
      if ((direction === -1 && this.activeIndex === 0)) {
         this.activeIndex=this.sliderBlock.current.children.length
      }
      if ((direction === 1 && this.activeIndex === this.sliderBlock.current.children.length - 1)) {
         this.activeIndex=0
      } else {
         this.activeIndex+=direction
      }
      this.setTranslateValue()
      // this.forceUpdate()
   }
   setTranslateValue = () => {
      this.calculateItem()
      this.setState({
         translateValue: this.activeIndex * this.itemWidth
      })

   }
   changeActiveIndex = (activeIndex) => {
      this.activeIndex = activeIndex
      this.setTranslateValue()
   }
   resizingWindow = () => {
      this.setTranslateValue()
   };
   handleTouchStart = (evt) => {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
   };
   handleTouchMove = (evt) => {
      if (!this.xDown || !this.yDown) {
         return;
      }
      this.xUp = evt.touches[0].clientX;
      this.yUp = evt.touches[0].clientY;

      let xDiff = this.xDown - this.xUp;
      let yDiff = this.yDown - this.yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
         evt.preventDefault();
         evt.stopPropagation();
         if (xDiff > 0) {
            this.slideFunction(1);
         } else {
            this.slideFunction(-1);
         }
      }

      this.xDown = null;
      this.yDown = null;
   }
   resetTranslateVal = () => {
      this.activeIndex=0
      this.setState({
         translateValue:0
      })
      this.forceUpdate()
   }

   componentWillUnmount() {
      if (typeof window !== "undefined") {
         this.sliderBlock.current.removeEventListener('touchstart', this.handleTouchStart, false);
         this.sliderBlock.current.removeEventListener('touchmove', this.handleTouchMove, false);
         window.removeEventListener('resize', this.resizingWindow);
      }
   }
   render() {
      return (
          <div className="slider-wrapper">
             <div className="slider-container">
                <div className={`arr-item `} onClick={() => this.slideFunction(-1)}/>
                <div className={`arr-item right-arr`} onClick={() => this.slideFunction(1)}/>
                <div className="slider-block" ref={this.sliderBlock}
                     style={{transform: `translate3d(-${this.state.translateValue}px,0,0)`}}>
                   {
                      this.props.children
                   }
                </div>
             </div>
             {
                <SmallSliderComponent children={this.props.children}  activeIndex={this.activeIndex} changeIndex={this.changeActiveIndex}/>
             }
          </div>
      );
   }
}

export default SliderComponent;
